import { WorkOrderPriority, WorkOrderStatus } from '@/graphql/types';
import { IAsset, IDisplayAsset } from '@/modules/assets/types';
import { ICheckList } from '@/modules/checkList';
import { IDisplayGroup } from '@/modules/groups';
import { IDisplayPart } from '@/modules/parts/types';
import { IDisplayProduct } from '@/modules/products';
import { IDisplayUser } from '@/modules/users';

export const WorkOrderAllStatuses: WorkOrderStatus[] = ['open', 'onHold', 'inProgress', 'done'];

export const WorkOrderDoneStatus: WorkOrderStatus = 'done';

export const WorkOrderNotDoneStatuses: WorkOrderStatus[] = ['open', 'onHold', 'inProgress'];

interface IWorkOrderRequest {
  id: number;
  createdAt: Date;
  createdBy: IDisplayUser;
}

export interface IWorkOrder {
  id: number;
  title?: string;
  assetId?: number;
  productId?: number;
  asset?: IDisplayAsset;
  product?: IDisplayProduct;
  description?: string | null;
  status: WorkOrderStatus;
  priority: WorkOrderPriority;
  createdAt: Date;
  createdBy?: IDisplayUser;
  updatedAt: Date;
  updatedBy?: IDisplayUser;
  stoppage?: IWorkOrderStoppage;
  stoppageReason?: {
    id: number;
    name: string;
  };
  customFieldTextValues: IWorkOrderCustomFieldTextValue[];
  customFieldIntValues: IWorkOrderCustomFieldIntValue[];
  customFieldFloatValues: IWorkOrderCustomFieldFloatValue[];
  customFieldSelectValues: IWorkOrderCustomFieldSelectValue[];
  customFieldDatetimeValues: IWorkOrderCustomFieldDatetimeValue[];
  customFieldDateValues: IWorkOrderCustomFieldDateValue[];
  customFieldUserValues: IWorkOrderCustomFieldUserValue[];
  customFieldFileValues: IWorkOrderCustomFieldFileValue[];
  dueDate?: Date;
  assignees?: IDisplayUser[];
  groups?: IDisplayGroup[];
  parts: IWorkOrderPart[];
  checkLists: ICheckList[];
  templateId: number;
  isScheduled: boolean;
  commentCount?: number;
  requests?: IWorkOrderRequest[];
}

export interface IWorkOrderStoppage {
  startAt?: Date;
  endAt?: Date;
}

export interface IWorkOrderCustomFieldTextValue {
  customFieldId: number;
  value: string;
}

export interface IWorkOrderCustomFieldFloatValue {
  customFieldId: number;
  value: number;
}

export interface IWorkOrderCustomFieldIntValue {
  customFieldId: number;
  value: number;
}

export interface IWorkOrderCustomFieldSelectValue {
  customFieldId: number;
  optionId: number;
  value: string;
  categoryName?: string | null;
  remark?: string;
}

export interface IWorkOrderCustomFieldDatetimeValue {
  customFieldId: number;
  value: Date;
}

export interface IWorkOrderCustomFieldDateValue {
  customFieldId: number;
  value: Date;
}

export interface IWorkOrderCustomFieldUserValue {
  customFieldId: number;
  user: IDisplayUser;
}

export interface IWorkOrderCustomFieldFileValue {
  id?: number;
  customFieldId: number;
  name: string;
  contentType: string;
  fileId: string;
  path?: string;
  src: string;
}

export interface ICountWorkOrderByAsset {
  id: number;

  name: string;

  level: number;

  count: number;

  countIncludingChild: number;

  parentAsset: IAsset;
}

export interface IWorkOrderPart {
  partId?: number;
  quantity: number;
  part?: IDisplayPart;
}

export interface IWorkOrderAssigneeInput {
  userId: string;
}

export interface IDisplayWorkOrder {
  id: number;
  title?: string;
  asset: IDisplayAsset;
  status: WorkOrderStatus;
  stoppage?: IWorkOrderStoppage;
  dueDate?: Date;
  updatedAt: Date;
}

export interface IWorkOrderScheduleDueDateInput {
  date: string;
}

export interface IWorkOrderScheduleWeeklyDateInput {
  weeklyId: number;
  date: string;
}
export interface IWorkOrderScheduleMonthlyDateInput {
  monthlyId: number;
  date: string;
}

export interface IWorkOrderScheduleMonthlyDayOfWeekDateInput {
  monthlyDayOfWeekId: number;
  date: string;
}

export interface IWorkOrderCustomFieldTextValueInput {
  customFieldId: number;
  value: string;
}

export interface IWorkOrderCustomFieldIntValueInput {
  customFieldId: number;
  value: number;
}
export interface IWorkOrderCustomFieldFloatValueInput {
  customFieldId: number;
  value: number;
}

export interface IWorkOrderCustomFieldSelectValueInput {
  customFieldId: number;
  optionId: number;
}

export interface IWorkOrderCustomFieldDatetimeValueInput {
  customFieldId: number;
  value: Date;
}

export interface IWorkOrderCustomFieldDatetimeUserInput {
  customFieldId: number;
  userId: string;
}

export interface IWorkOrderCustomFieldDateValueInput {
  customFieldId: number;
  value: Date;
}

export interface IWorkOrderCustomFieldUserValueInput {
  customFieldId: number;
  userId: string;
}

export interface IWorkOrderCustomFieldFileValueInput {
  customFieldId: number;
  name: string;
  contentType: string;
  fileId: string;
}
